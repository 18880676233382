import React, { useCallback, useEffect, useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "./Signal.css";
import signal from "./signal.svg";
import { sse } from "../utils/sse";

async function getSignalState() {
  let resp = await fetch("/api/signal");
  return await resp.json();
}

async function patchLight(color, value) {
  let resp = await fetch("/api/signal", {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ lights: { [color]: value } }),
  });
  return await resp.json();
}

async function patchMuliselect(isMultiselect) {
  let resp = await fetch("/api/signal", {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ multiselect: isMultiselect }),
  });
  return await resp.json();
}

function Signal() {
  const [isMultiselect, setIsMultiselect] = useState(false);

  const [lightState, setLightState] = useState({
    RED: null,
    YELLOW: null,
    GREEN: null,
  });

  function updateSignalState(state) {
    setLightState(state["lights"]);
    setIsMultiselect(state["multiselect"]);
  }

  async function loadState() {
    const resp = await getSignalState();
    updateSignalState(resp);
  }

  async function flipState(color) {
    const resp = await patchLight(color, !lightState[color]);
    updateSignalState(resp);
  }

  async function updateMultiselect(value) {
    const resp = await patchMuliselect(value);
    updateSignalState(resp);
  }

  useEffect(() => {
    loadState();
  }, []);

  useEffect(() => {
    sse.on("SIGNAL_STATE", updateSignalState);
    return () => {
      sse.off("SIGNAL_STATE", updateSignalState);
    };
  }, []);

  // Window key handlers allow for keyboard shortcuts
  function windowKeyDown(event) {
    if (!event.shiftKey && !event.ctrlKey && !event.metaKey && !event.altKey) {
      if (event.key === "r") {
        flipState("RED");
      } else if (event.key === "y") {
        flipState("YELLOW");
      } else if (event.key === "g") {
        flipState("GREEN");
      }
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", windowKeyDown);
    return () => {
      window.removeEventListener("keydown", windowKeyDown);
    };
  });

  return (
    <div className='Signal-Container'>
      <div className='Signal-Toggle'>
        <div className='Spacer'></div>
        <span>Single Mode</span>
        <Toggle
          icons={false}
          checked={isMultiselect}
          onChange={(e) => updateMultiselect(e.target.checked)}
        />
        <span>Multi Mode</span>
        <div className='Spacer'></div>
      </div>

      <div className='Signal-Control'>
        <img src={signal} alt='signal' />
        <div
          className={`Light Red ${!lightState.RED && "Disabled"}`}
          onClick={() => flipState("RED")}
        />
        <div
          className={`Light Yellow ${!lightState.YELLOW && "Disabled"}`}
          onClick={() => flipState("YELLOW")}
        />
        <div
          className={`Light Green ${!lightState.GREEN && "Disabled"}`}
          onClick={() => flipState("GREEN")}
        />
      </div>
    </div>
  );
}

export default Signal;
