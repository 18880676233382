import setupEventEmitter from "event-emitter";

class SSESubscriber {
  constructor() {
    this.reconnectFrequencySeconds = 1;
    this.evtSource = null;
    this._setupEventSource();
  }

  _setupEventSource() {
    this._closeEventSource();

    this.evtSource = new EventSource("/api/stream");
    this.evtSource.onopen = (e) => {
      this.reconnectFrequencySeconds = 1;
    };
    this.evtSource.onerror = (e) => {
      this._closeEventSource();
      this._reconnectEventSource();
    };
    this.evtSource.addEventListener(
      "message",
      (event) => {
        const message = JSON.parse(event.data);
        this.emit(message.type, message.data);
      },
      false
    );
  }

  _closeEventSource() {
    if (this.evtSource) {
      this.evtSource.close();
      this.evtSource = null;
    }
  }

  _reconnectEventSource() {
    const timeout = this.reconnectFrequencySeconds * 1000;
    setTimeout(() => {
      this._setupEventSource();
      this.reconnectFrequencySeconds *= 2;
      if (this.reconnectFrequencySeconds >= 64) {
        this.reconnectFrequencySeconds = 64;
      }
    }, timeout);
  }
}

setupEventEmitter(SSESubscriber.prototype);

export const sse = new SSESubscriber();
