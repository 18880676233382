import React from "react";
import "./Legend.css";

function Legend() {
  return (
    <div className='Legend-Container'>
      <label className='Legend-Label'>Shortcuts</label>
      <div className='Legend-Button-Row'>
        <div className='Legend-Button Red'>R</div>
        <div className='Legend-Button Yellow'>Y</div>
        <div className='Legend-Button Green'>G</div>
      </div>
    </div>
  );
}

export default Legend;
