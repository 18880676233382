import "./App.css";
import Legend from "./components/Legend";
import Signal from "./components/Signal";

function App() {
  return (
    <div className='App'>
      <Signal />
      <Legend />
    </div>
  );
}

export default App;
